import { render, staticRenderFns } from "./EditorManagement.vue?vue&type=template&id=6ed29664&scoped=true&"
import script from "./EditorManagement.vue?vue&type=script&lang=js&"
export * from "./EditorManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ed29664",
  null
  
)

export default component.exports